<template>
  <div class="pa-2">
    <v-combobox
      ref="enterpriseCombo"
      v-model="comboboxModel" 
      hide-details 
      solo 
      label="Enterprises"
      :items="items"
      item-text="comboName"
      item-value="id"
      :loading="loading"
      return-object
      dense
      @input.native="handleSearch($event.srcElement.value)"
      @change="handleChange"
      @focus="handleFocus"
    />
  </div>
</template>

<script>
import GH from '@/util/helpers'

export default {
  data() {
    return {
      items: [],
      loading: false,
      options: {
        page: 1,
        itemsPerPage: 5,
        params: []
      },
      comboboxModel: null
    }
  },
  watch: {
    '$store.getters.getActivateEnterprise': {
      handler () {
        this.comboboxModel = this.$store.getters.getActivateEnterprise
      },
      deep: true,
    }
  },
  created() {
    this.comboboxModel = JSON.parse(localStorage.getItem('enterprise'))
  },
  methods: {
    checkEnterpriseExists(items) {
      if (items.some(item => JSON.stringify({ id: item.id, displayName: item.displayName, comboName: `${item.displayName} (${item.name})`, name: item.name}) === localStorage.getItem('enterprise'))) {
        return true
      }
      return false
    },
    getListEnterprises() {
      this.loading = true
      this.$store
        .dispatch('listEnterprise', this.options)
        .then(() => {
          this.loading = false
          this.items = this.$store.getters.getAllEnterprises.content.map(item => ({ id: item.id, displayName: item.displayName, comboName: `${item.displayName} (${item.name})`, name: item.name}))
                    
          // Set or delete enterprise on local storage if doesn't exists
          if (!this.checkEnterpriseExists(this.$store.getters.getAllEnterprises.content)) {
            this.$store
              .dispatch('deactivateGlobalEnterprise')
              .then(() => {
                this.comboboxModel = JSON.parse(localStorage.getItem('enterprise'))
              })
              .catch((error) => {
                this.$log.error(error)
                GH.alertSnackbar(this.$store, error)
              })
          }
        })
        .catch((error) => {
          this.$log.error(error)
          this.loading = false
          GH.alertSnackbar(this.$store, error)
        })
    },
    handleChange(val) {
      this.loading = true
      this.$store
        .dispatch('selectGlobalEnterprise', val)
        .then(() => {
          this.loading = false
          const routerName = 'dashboard'
          if (this.$route.name !== routerName) this.$router.push({name: routerName})
        })
        .catch((error) => {
          this.$log.error(error);
          this.loading = false
          GH.alertSnackbar(this.$store, error)
        })
    },
    handleSearch(val) {
      if(GH.checkEnterpriseSelected()) {
        val = JSON.parse(localStorage.getItem('enterprise')).displayName
      }
      this.options.params.displayName = val
      this.getListEnterprises()
    },
    handleFocus() {
      this.$emit("enableMiniDrawer", false)
      delete this.options.params.displayName
      this.getListEnterprises()
    },
  },
}
</script>  