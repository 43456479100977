<template>
  <div class="d-flex align-center">
    <v-dialog
      v-if="$vuetify.breakpoint.mobile"
      v-model="loadingSubmit"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Creating user
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <div
      v-if="!$vuetify.breakpoint.mobile"
    >
      <v-btn
        :loading="loadingSubmit"
        :disabled="loadingSubmit"
        color="primary"
        class="mx-1"
        @click="clickBtnSave(true)"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Submit
      </v-btn>
      <v-btn
        :to="{ name: 'users-list' }"
        class="mx-1"
      >
        <v-icon left>
          mdi-close
        </v-icon>
        Cancel
      </v-btn>
    </div>
    <v-menu
      v-else
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          class="mx-2"
          v-on="on"
        >
          <v-icon>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          class="pl-5 pr-6"
          @click="clickBtnSave(true)"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>Submit</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>Submit User</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item
          class="pl-5 pr-6"
          :to="{ name: 'users-list' }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-close</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>Cancel</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>Back to List</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadingSubmit: false,
    }
  },
  watch: {
    '$store.getters.getBtnSaveLoadingUserAdd': {
      handler () {
        this.loadingSubmit = this.$store.getters.getBtnSaveLoadingUserAdd
      },
      deep: true,
    },
  },
  methods: {
    clickBtnSave(val) {
      this.$store.dispatch('btnClickSaveUserAdd', val)
    },
  },
}
</script>