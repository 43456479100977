import Vue from 'vue'
import axios from 'axios';
import GH from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  roles: [],
  role: {},
  // Role add
  btnSaveRoleAdd: null,
  btnSaveLoadingRoleAdd: null,
  // Role edit
  btnSaveRoleEdit: null,
  btnSaveLoadingRoleEdit: null,
}

const getters = {
  getAllRoles: state => state.roles,
  getRole: state => state.role,
  // Role add
  getBtnSaveRoleAdd: state => state.btnSaveRoleAdd,
  getBtnSaveLoadingRoleAdd: state => state.btnSaveLoadingRoleAdd,
  // Role edit
  getBtnSaveRoleEdit: state => state.btnSaveRoleEdit,
  getBtnSaveLoadingRoleEdit: state => state.btnSaveLoadingRoleEdit,
}

const actions = {
  async listRoles({ commit }, options) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/roles?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    await axios(config)
      .then((response) => {
        commit('setRoles', response.data)
      })
  },

  async viewRole({ commit }, pathVar) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/roles/${pathVar.roleId}/organisations/${pathVar.orgId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
    await axios(config)
      .then((response) => {
        commit('setRole', response.data)
      })
  },

  async createRole({}, params) {
    var data = new FormData()
    data.append('roleName', params.name)
    data.append('roleType', params.type)
    data.append('orgId', params.org.id)
    params.permissions.map((permission) => {
      data.append('permissionId', permission.id)
    })
        
    var config = {
      method: 'post',
      url: `${resource_uri}/api/roles`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
    return await axios(config)
  },

  async updateRole({}, params) {
    var data = new FormData()
    data.append('roleName', params.role.name)
    data.append('roleType', params.role.type)
    data.append('newOrgId', params.role.org.id)
    params.role.permissions.map((permission) => {
      data.append('permissionId', permission.id)
    })
        
    var config = {
      method: 'patch',
      url: `${resource_uri}/api/roles/${params.roleId}/organisations/${params.orgId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
    return await axios(config)
  },

  async deleteRole({}, id) {
    var config = {
      method: 'delete',
      url: `${resource_uri}/api/roles/${id}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
    await axios(config)
  },

  // Role add button trigger
  async btnClickSaveRoleAdd({ commit }, params) {
    commit('setBtnSaveRoleAdd', params)
  },
  async btnClickSaveLoadingRoleAdd({ commit }, params) {
    commit('setBtnSaveLoadingRoleAdd', params)
  },

  // Role edit button trigger
  async btnClickSaveRoleEdit({ commit }, params) {
    commit('setBtnSaveRoleEdit', params)
  },
  async btnClickSaveLoadingRoleEdit({ commit }, params) {
    commit('setBtnSaveLoadingRoleEdit', params)
  },
};

const mutations = {
  setRoles(state, roles) {
    state.roles = roles
  },
  setRole(state, role) {
    state.role = role
  },
  // Role add
  setBtnSaveRoleAdd(state, params) {
    state.btnSaveRoleAdd = params
  },
  setBtnSaveLoadingRoleAdd(state, params) {
    state.btnSaveLoadingRoleAdd = params
  },
  // Role edit
  setBtnSaveRoleEdit(state, params) {
    state.btnSaveRoleEdit = params
  },
  setBtnSaveLoadingRoleEdit(state, params) {
    state.btnSaveLoadingRoleEdit = params
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}