<template>
  <v-snackbar
    v-model="snackbar.visible"
    :timeout="snackbar.timeout"
    :top="snackbar.y === 'top'"
    :bottom="snackbar.y === 'bottom'"
    :left="snackbar.x ==='left'"
    :right="snackbar.x === 'right'"
    :multi-line="snackbar.mode === 'multi-line'"
    :vertical="snackbar.mode === 'vertical'"
    :color="snackbar.color"
  >
    <v-icon left>
      {{ snackbar.icon }}
    </v-icon>
    {{ snackbar.text }}

    <template #action="{ attrs }">
      <v-btn
        dark
        v-bind="attrs"
        icon
        @click="snackbar.visible = false"
      >
        <v-icon dark>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
export default {
  computed: {
    snackbar() {
      return this.$store.state.alert.snackbar
    }
  },
}
</script>