export default [
  {
    path: '/enterprises/:enterpriseId/devices',
    name: 'devices-list',
    meta: {
      layout: 'vertical',
      title: 'Devices',
      icon: 'mdi-devices',
      action: 'devices_list',
      toolbarAction: 'DeviceListToolbar',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/devices/List.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/devices/action-history',
    name: 'devices-action-history',
    meta: {
      layout: 'vertical',
      title: 'Action History',
      icon: 'mdi-clipboard-text-clock',
      hidden: true,
      action: 'devices_action_history',
      breadcrumbRedirect: '/enterprises/:enterpriseId/devices',
      breadcrumbsTitle: 'Devices',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/devices/ListActionHistory.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/devices/messages',
    name: 'devices-message',
    meta: {
      layout: 'vertical',
      title: 'Messages',
      icon: 'mdi-message-outline',
      hidden: true,
      action: 'devices_message',
      breadcrumbRedirect: '/enterprises/:enterpriseId/devices',
      breadcrumbsTitle: 'Devices',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/devices/DeviceMessage.vue'),
  },
]