import Vue from 'vue'
import axios from 'axios'
import GH from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  enterprises: [],
  activateEnterprise: {},
  // Enterprise add
  btnSaveEnterpriseAdd: null,
  btnSaveLoadingEnterpriseAdd: null,
  // Enterprise edit
  btnSaveEnterpriseEdit: null,
  btnSaveLoadingEnterpriseEdit: null,
};

const getters = {
  getAllEnterprises: state => state.enterprises,
  getActivateEnterprise: state => state.activateEnterprise,
  // Enterprise add
  getBtnSaveEnterpriseAdd: state => state.btnSaveEnterpriseAdd,
  getBtnSaveLoadingEnterpriseAdd: state => state.btnSaveLoadingEnterpriseAdd,
  // Enterprise edit
  getBtnSaveEnterpriseEdit: state => state.btnSaveEnterpriseEdit,
  getBtnSaveLoadingEnterpriseEdit: state => state.btnSaveLoadingEnterpriseEdit,
};

const actions = {
  async listEnterprise({ commit }, options) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
    await axios(config)
      .then((response) => {
        commit('setEnterprises', response.data)
      })
  },

  async createEnterprise({}, params) {

    var data = new FormData()
    data.append('projectId', params.projectId)
    data.append('signupUrlName', params.signupUrl)
    data.append('enterpriseToken', params.enterpriseToken)
    if (params.ztCustomerId) {
      data.append('ztCustomerId', params.ztCustomerId)
    }
        
    var config = {
      method: 'post',
      url: `${resource_uri}/api/enterprises`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async updateEnterprise({}, params) {
    var data = JSON.stringify(params.body)
        
    var config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: data
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async deleteEnterprise({}, id) {
    var config = {
      method: 'delete',
      url: `${resource_uri}/api/enterprises/${id}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
    await axios(config)
  },

  async viewEnterprise({}, enterpriseId) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async selectGlobalEnterprise({ commit }, selectedEnterprise) {
    commit('setGlobalEnterprise', selectedEnterprise)
  },

  async refreshGlobalEnterprise({ commit }, selectedEnterprise) {
    commit('setRefreshGlobalEnterprise', selectedEnterprise)
  },

  async deactivateGlobalEnterprise({ commit }) {
    commit('setGlobalEnterprise', null)
  },

  // Enterprise add button trigger
  async btnClickSaveEnterpriseAdd({ commit }, params) {
    commit('setBtnSaveEnterpriseAdd', params)
  },
  async btnClickSaveLoadingEnterpriseAdd({ commit }, params) {
    commit('setBtnSaveLoadingEnterpriseAdd', params)
  },

  // Enterprise edit button trigger
  async btnClickSaveEnterpriseEdit({ commit }, params) {
    commit('setBtnSaveEnterpriseEdit', params)
  },
  async btnClickSaveLoadingEnterpriseEdit({ commit }, params) {
    commit('setBtnSaveLoadingEnterpriseEdit', params)
  },
};

const mutations = {
  setEnterprises(state, enterprises) {
    state.enterprises = enterprises
  },
  setGlobalEnterprise({}, selectedEnterprise) {
    state.activateEnterprise = selectedEnterprise
    selectedEnterprise ? localStorage.setItem('enterprise', JSON.stringify(selectedEnterprise)) : localStorage.removeItem('enterprise')
  },
  setRefreshGlobalEnterprise({}, selectedEnterprise) {
    state.activateEnterprise = selectedEnterprise
  },
  // Enterprise add
  setBtnSaveEnterpriseAdd(state, params) {
    state.btnSaveEnterpriseAdd = params
  },
  setBtnSaveLoadingEnterpriseAdd(state, params) {
    state.btnSaveLoadingEnterpriseAdd = params
  },
  // Enterprise edit
  setBtnSaveEnterpriseEdit(state, params) {
    state.btnSaveEnterpriseEdit = params
  },
  setBtnSaveLoadingEnterpriseEdit(state, params) {
    state.btnSaveLoadingEnterpriseEdit = params
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}