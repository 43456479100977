<template>
  <v-list
    nav
  >
    <template v-for="(item, key) in items">
      <v-list-group
        v-if="hasChild(item)"
        :key="key"
        no-action
        :to="item.path"
        :value="computeGroupExpanded(item, $route)"
      >
        <template #prependIcon>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                v-text="item.icon"
              />
            </template>
            <span v-text="item.title" />
          </v-tooltip>
        </template>
        <template #activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </template>
        <NavListItem
          v-for="child in item.children"
          :key="'c' + child.path"
          :item="child"
        />
      </v-list-group>
      <NavListItem
        v-else
        :key="'nav' + key"
        :item="item"
      />
    </template>
  </v-list>
</template>

<script>
import NavListItem from './NavListItem'
export default {
  components: {
    NavListItem,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    hasChild(item) {
      return Array.isArray(item.children) && item.children.length > 0
    },
    computeGroupExpanded(item, $route) {
      return $route.matched.map((item) => item.path).includes(item.path)
    },
  },
}
</script>
<style lang="scss" scoped>
  .v-list--nav .v-list-group {
    margin-bottom: 8px;
  }
</style>