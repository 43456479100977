export default [
  {
    path: '/organisations',
    name: 'organisations-list',
    meta: {
      layout: 'vertical',
      title: 'Organisations',
      icon: 'mdi-office-building',
      action: 'organisations_list'
    },
    component: () => import('@/views/organisations/List.vue'),
  },
  {
    path: '/organisations/add',
    name: 'organisations-create',
    meta: {
      layout: 'vertical',
      title: 'Add Organisation',
      icon: 'mdi-plus',
      action: 'organisations_create',
      hidden: true,
      breadcrumbAction: 'OrganisationAddAction',
      breadcrumbRedirect: '/organisations',
      breadcrumbsTitle: 'Organisations'
    },
    component: () => import('@/views/organisations/Add.vue'),
  },
  {
    path: '/organisations/:id/edit',
    name: 'organisations-update',
    meta: {
      layout: 'vertical',
      title: 'Edit Organisation',
      icon: 'mdi-pencil',
      hidden: true,
      action: 'organisations_update',
      breadcrumbAction: 'OrganisationEditAction',
      breadcrumbRedirect: '/organisations',
      breadcrumbsTitle: 'Organisations'
    },
    component: () => import('@/views/organisations/Edit.vue'),
  },
]