export default [
  {
    path: '/enterprises/:enterpriseId/policies',
    name: 'policies-list',
    meta: {
      layout: 'vertical',
      title: 'Policies',
      icon: 'mdi-shield-lock',
      action: 'policies_list',
      toolbarAction: 'PolicyListToolbar',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/policies/List.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/policies/add',
    name: 'policies-create',
    meta: {
      layout: 'vertical',
      title: 'Add Policy',
      icon: 'mdi-plus',
      action: 'policies_create',
      hidden: true,
      toolbarAction: 'PolicyAddToolbar',
      breadcrumbAction: 'PolicyAddAction',
      breadcrumbRedirect: '/enterprises/:enterpriseId/policies',
      breadcrumbsTitle: 'Policies',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/policies/Add.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/policies/:id/edit',
    name: 'policies-update',
    meta: {
      layout: 'vertical',
      title: 'Edit Policy',
      icon: 'mdi-pencil',
      hidden: true,
      action: 'policies_update',
      toolbarAction: 'PolicyEditToolbar',
      breadcrumbAction: 'PolicyEditAction',
      breadcrumbRedirect: '/enterprises/:enterpriseId/policies',
      breadcrumbsTitle: 'Policies',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/policies/Edit.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/policies/:id/view',
    name: 'policies-view',
    meta: {
      layout: 'vertical',
      title: 'View Policy',
      icon: 'mdi-format-list-bulleted',
      hidden: true,
      action: 'policies_view',
      breadcrumbAction: 'PolicyViewAction',
      breadcrumbRedirect: '/enterprises/:enterpriseId/policies',
      breadcrumbsTitle: 'Policies',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/policies/View.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/policies/import',
    name: 'policies-import',
    meta: {
      layout: 'vertical',
      title: 'Import Policy',
      icon: 'mdi-database-import',
      action: 'policies_create',
      hidden: true,
      breadcrumbAction: 'PolicyImportAction',
      breadcrumbRedirect: '/enterprises/:enterpriseId/policies',
      breadcrumbsTitle: 'Policies',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/policies/Import.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/policies/qr-search',
    name: 'policies-qr-search',
    meta: {
      layout: 'vertical',
      title: 'Search QR',
      icon: 'mdi-qrcode-scan',
      action: 'enrollment_tokens_view',
      hidden: true,
      breadcrumbAction: 'PolicyQrSearchAction',
      breadcrumbRedirect: '/enterprises/:enterpriseId/policies',
      breadcrumbsTitle: 'Policies',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/policies/SearchQr.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/policies/:id/qr',
    name: 'policies-qr',
    meta: {
      layout: 'vertical',
      title: 'View QR',
      icon: 'mdi-qrcode',
      hidden: true,
      action: 'enrollment_tokens_view',
      breadcrumbAction: 'PolicyViewQrAction',
      breadcrumbRedirect: '/enterprises/:enterpriseId/policies',
      breadcrumbsTitle: 'Policies',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/policies/ManageQr.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/policies/compare',
    name: 'policies-compare',
    meta: {
      layout: 'vertical',
      title: 'Compare Policies',
      icon: 'mdi-file-compare',
      action: 'policies_compare',
      hidden: true,
      breadcrumbAction: 'PolicyCompareAction',
      breadcrumbRedirect: '/enterprises/:enterpriseId/policies',
      breadcrumbsTitle: 'Policies',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/policies/Compare.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/policies/history',
    name: 'policies-history',
    meta: {
      layout: 'vertical',
      title: 'History',
      icon: 'mdi-clipboard-text-clock',
      action: 'policies_log',
      hidden: true,
      breadcrumbRedirect: '/enterprises/:enterpriseId/policies',
      breadcrumbsTitle: 'Policies',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/policies/History.vue'),
  },
]