import colors from 'vuetify/es5/util/colors'
import Vue from 'vue'
import axios from 'axios'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const randomColor = () => {
  const temp = Object.keys(colors)
  const key = temp[Math.floor(Math.random() * temp.length)]
  return colors[key].base
}

const state = {
  username: null,
  avatar: null,
  userColor: randomColor(),
};

const getters = {
  getAvatar: (state) => state.avatar,
  getUsername: (state) => state.username,
  getUserColor: (state) => state.userColor,
};

const actions = {
  fetchProfile({ commit }, userData) {
    commit('setLoginProfile', userData);
  },

  async getAbility({}, userData) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/auth/abilities/${userData.tokenParsed.preferred_username}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }

    return await axios(config)
      .then((response) => {
        const data = response.data.map(item => ({ action: item.name, subject: 'all' }))
        // Push general action
        data.push({ action: 'initial', subject: 'all' })
        Vue.$cookies.set('abilities', data)
        return data
      })
  },

  async logout({}, email) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/auth/logout/${email}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
        
    await axios(config)
  },
};

const mutations = {
  setLoginProfile(state, userData) {
    state.username = userData.tokenParsed.preferred_username
    state.avatar = null
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}