<template>
  <div class="d-flex align-center">
    <div
      v-if="!$vuetify.breakpoint.mobile"
    >
      <v-btn
        text
        class="mx-1"
        @click="clickBtnJsonView(true)"
      >
        <v-icon left>
          mdi-code-json
        </v-icon>
        JSON View
      </v-btn>
    </div>
    <v-menu
      v-else
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          class="mx-2"
          v-on="on"
        >
          <v-icon>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          class="pl-5 pr-6"
          @click="clickBtnJsonView(true)"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-code-json</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>JSON View</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>JSON View</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    clickBtnJsonView(val) {
      this.$store.dispatch('btnClickJsonViewPolicyAdd', val)
    },
  },
}
</script>