<template>
  <v-btn
    icon
    @click="handleFullScreen()"
  >
    <v-icon>mdi-fullscreen</v-icon>
  </v-btn>
</template>
<script>
import GH from '@/util/helpers'

export default {
  methods: {
    handleFullScreen() {
      GH.toggleFullScreen()
    },
  }
}
</script>