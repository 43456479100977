import Vue from 'vue'
import axios from 'axios'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
};

const getters = {
};

const actions = {
  async createWebToken({}, params) {
    var data = new FormData()
    data.append('parentFrameUrl', params.parentFrameUrl)
    if (params.permissions) {
      params.permissions.map((permission) => {
        data.append('permission', permission)
      })
    }
    if (params.enabledFeatures) {
      params.enabledFeatures.map((enabledFeature) => {
        data.append('enabledFeature', enabledFeature)
      })
    }
        
    var config = {
      method: 'post',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}:createWebTokens`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
        
    return await axios(config)
  },
};

const mutations = {
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}