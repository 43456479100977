export default [
  {
    path: '/enterprises/:enterpriseId/zero-touch',
    name: 'zero-touch',
    meta: {
      layout: 'vertical',
      title: 'Zero Touch',
      icon: 'mdi-braille',
      action: 'zero_touch_iframe',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/zero-touch/IframeManage.vue'),
  },
]