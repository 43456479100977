import Vue from 'vue'
import axios from 'axios';

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL

const state = {
  enrollmentToken: {}
};

const getters = {
  getEnrollmentToken: state => state.enrollmentToken
};

const actions = {
  async viewEnrollmentToken({ commit }, params) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies/${params.policyId}:getEnrollmentToken`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
    return await axios(config)
      .then((response) => {
        commit('setEnrollmentToken', response.data)
        return response.data
      })
  },

  async updateEnrollmentToken({}, params) {
    var data = new FormData()
    data.append('qrCode', params.qrCode)
        
    var config = {
      method: 'patch',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/policies/${params.policyId}:updateEnrollmentToken`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },
};

const mutations = {
  setEnrollmentToken(state, enrollmentToken) {
    state.enrollmentToken = enrollmentToken
  }
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}