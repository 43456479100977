const abilityList = () => {
  return { // Just needed ability list on UI
    enterprisesList: 'enterprises_list',
    enterprisesUpdate: 'enterprises_update',
    enterprisesCreate: 'enterprises_create',
    enterprisesDelete: 'enterprises_delete',
    devicesList: 'devices_list',
    devicesDelete: 'devices_delete',
    devicesIssueCommand: 'devices_issue_command',
    devicesUpdate: 'devices_update',
    devicesView: 'devices_view',
    devicesActionHistory: 'devices_action_history',
    devicesMessage: 'devices_message',
    enrollmentTokensCreate: 'enrollment_tokens_create',
    enrollmentTokensView: 'enrollment_tokens_view',
    enrollmentTokensUpdate: 'enrollment_tokens_update',
    policiesList: 'policies_list',
    policiesCreate: 'policies_create',
    policiesUpdate: 'policies_update',
    policiesDelete: 'policies_delete',
    policiesView: 'policies_view',
    policiesLog: 'policies_log',
    policiesCompare: 'policies_compare',
    signupUrlsCreate: 'signup_urls_create',
    rolesList: 'roles_list',
    rolesCreate: 'roles_create',
    rolesView: 'roles_view',
    rolesUpdate: 'roles_update',
    rolesDelete: 'roles_delete',
    permissionsList: 'permissions_list',
    customersCreate: 'customers_create',
    customersList: 'customers_list',
    applicationsIframe: 'applications_iframe',
    applicationsDelete: 'applications_delete',
    organisationsList: 'organisations_list',
    organisationsCreate: 'organisations_create',
    organisationsUpdate: 'organisations_update',
    organisationsView: 'organisations_view',
    organisationsDelete: 'organisations_delete',
    usersList: 'users_list',
    usersCreate: 'users_create',
    usersDelete: 'users_delete',
    usersView: 'users_view',
    usersUpdate: 'users_update',
  }
}

const wildcards = () => {
  return {
    emailAddress: '$emailAddress$',
    username: '$username$',
  }
}

const wipeDataFlag = () => {
  return {
    preserveResetProtectionData: 'PRESERVE_RESET_PROTECTION_DATA',
    wipeExternalStorage: 'WIPE_EXTERNAL_STORAGE',
    wipeDataFlagUnspecified: 'WIPE_DATA_FLAG_UNSPECIFIED',
  }
}

const systemUpdateType = () => {
  return {
    systemUpdateTypeUnspecified: 'SYSTEM_UPDATE_TYPE_UNSPECIFIED',
    automatic: 'AUTOMATIC',
    windowed: 'WINDOWED',
    postpone: 'POSTPONE',
  }
}

const untrustedAppsPolicy = () => {
  return {
    untrustedAppsPolicyUnspecified: 'UNTRUSTED_APPS_POLICY_UNSPECIFIED',
    disallowInstall: 'DISALLOW_INSTALL',
    allowInstallInPersonalProfileOnly: 'ALLOW_INSTALL_IN_PERSONAL_PROFILE_ONLY',
    allowInstallDeviceWide: 'ALLOW_INSTALL_DEVICE_WIDE',
  }
}

const googlePlayProtectVerifyApps = () => {
  return {
    googlePlayProtectVerifyAppsUnspecified: 'GOOGLE_PLAY_PROTECT_VERIFY_APPS_UNSPECIFIED',
    verifyAppsEnforced: 'VERIFY_APPS_ENFORCED',
    verifyAppsUserChoice: 'VERIFY_APPS_USER_CHOICE',
  }
}

const developerSettings = () => {
  return {
    developerSettingsUnspecified: 'DEVELOPER_SETTINGS_UNSPECIFIED',
    developerSettingsDisabled: 'DEVELOPER_SETTINGS_DISABLED',
    developerSettingsAllowed: 'DEVELOPER_SETTINGS_ALLOWED',
  }
}

const permissionPolicy = () => {
  return {
    permissionPolicyUnspecified: 'PERMISSION_POLICY_UNSPECIFIED',
    prompt: 'PROMPT',
    grant: 'GRANT',
    deny: 'DENY',
  }
}

const commandType = () => {
  return {
    commandTypeUnspecified: 'COMMAND_TYPE_UNSPECIFIED',
    lock: 'LOCK',
    resetPassword: 'RESET_PASSWORD',
    reboot: 'REBOOT',
    relinquishOwnership: 'RELINQUISH_OWNERSHIP',
    clearAppData: 'CLEAR_APP_DATA',
  }
}

const passwordPolicies = () => {
  return {
    unspecified: [{}],
    low: [
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordQuality": "COMPLEXITY_LOW"
      },
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordQuality": "SOMETHING"
      }
    ],
    medium: [
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordQuality": "COMPLEXITY_MEDIUM"
      },
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordMinimumLength": 4,
        "passwordQuality": "NUMERIC_COMPLEX"
      },
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordMinimumLength": 4,
        "passwordQuality": "ALPHABETIC"
      },
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordMinimumLength": 4,
        "passwordQuality": "ALPHANUMERIC"
      }
    ],
    high: [
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordQuality": "COMPLEXITY_HIGH"
      },
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordMinimumLength": 8,
        "passwordQuality": "NUMERIC_COMPLEX"
      },
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordMinimumLength": 6,
        "passwordQuality": "ALPHABETIC"
      },
      {
        "passwordScope": "SCOPE_DEVICE",
        "passwordMinimumLength": 6,
        "passwordQuality": "ALPHANUMERIC"
      }
    ]
  }
}

const installType = () => {
  return {
    installTypeUnspecified: 'INSTALL_TYPE_UNSPECIFIED',
    preinstalled: 'PREINSTALLED',
    forceInstalled: 'FORCE_INSTALLED',
    blocked: 'BLOCKED',
    available: 'AVAILABLE',
    requiredForSetup: 'REQUIRED_FOR_SETUP',
    kiosk: 'KIOSK'
  }
}

const installTypeLabel = () => {
  return {
    INSTALL_TYPE_UNSPECIFIED: 'INSTALL TYPE UNSPECIFIED',
    PREINSTALLED: 'PREINSTALLED',
    FORCE_INSTALLED: 'FORCE INSTALLED',
    BLOCKED: 'BLOCKED',
    AVAILABLE: 'AVAILABLE',
    REQUIRED_FOR_SETUP: 'REQUIRED FOR SETUP',
    KIOSK: 'KIOSK'
  }
}

const permissionGrants = () => {
  return {
    camera: 'android.permission.CAMERA',
    recordAudio: 'android.permission.RECORD_AUDIO',
    accessFineLocation: 'android.permission.ACCESS_FINE_LOCATION',
    accessCoarseLocation: 'android.permission.ACCESS_COARSE_LOCATION',
  }
}

const autoUpdateMode = () => {
  return {
    modeUnspecified: 'AUTO_UPDATE_MODE_UNSPECIFIED',
    default: 'AUTO_UPDATE_DEFAULT',
    postponed: 'AUTO_UPDATE_POSTPONED',
    highPriority: 'AUTO_UPDATE_HIGH_PRIORITY',
  }
}

const managedPropertyType = () => {
  return {
    managedPropertyTypeUnspecified: 'MANAGED_PROPERTY_TYPE_UNSPECIFIED',
    bool: 'BOOL',
    string: 'STRING',
    integer: 'INTEGER',
    choice: 'CHOICE',
    multiselect: 'MULTISELECT',
    hidden: 'HIDDEN',
    bundle: 'BUNDLE',
    bundleArray: 'BUNDLE_ARRAY'
  }
}

const orgType = () => {
  return {
    private: 'PRIVATE',
    public: 'PUBLIC',
  }
}

const userType = () => {
  return {
    staff: 'STAFF',
    customer: 'CUSTOMER',
  }
}

const deviceState = () => {
  return {
    deviceStateUnspecified: 'DEVICE_STATE_UNSPECIFIED',
    active: 'ACTIVE',
    disabled: 'DISABLED',
    deleted: 'DELETED',
    provisioning: 'PROVISIONING',
  }
}

const appState = () => {
  return {
    applicationStateUnspecified: 'APPLICATION_STATE_UNSPECIFIED',
    removed: 'REMOVED',
    installed: 'INSTALLED',
  }
}

export const processStatus = () => {
  return {
    pending: 'PENDING',
    onProcess: 'ON_PROCESS',
    success: 'SUCCESS',
    failed: 'FAILED'
  }
}

const iframeFeature = () => {
  return {
    featureUnspecified: 'FEATURE_UNSPECIFIED',
    playSearch: 'PLAY_SEARCH',
    privateApps: 'PRIVATE_APPS',
    webApps: 'WEB_APPS',
    storeBuilder: 'STORE_BUILDER',
    managedConfigurations: 'MANAGED_CONFIGURATIONS',
    zeroTouchCustomerManagement: 'ZERO_TOUCH_CUSTOMER_MANAGEMENT',
  }
}

const webTokenPermissions = () => {
  return {
    webTokenPermissionUnspecified: 'WEB_TOKEN_PERMISSION_UNSPECIFIED',
    approveApps: 'APPROVE_APPS',
  }
}

const airplaneModeState = () => {
  return {
    airplaneModeUserChoice: 'AIRPLANE_MODE_USER_CHOICE',
    airplaneModeDisabled: 'AIRPLANE_MODE_DISABLED',
  }
}

const gApiUrl = () => {
  return {
    gapi: 'https://apis.google.com/js/api.js',
  }
}

const packageName = () => {
  return {
    comWssyncmldm: 'com.wssyncmldm'
  }
}

const devicePolicyManager = () => {
  return {
    provisioningKeepScreenOn: "android.app.extra.PROVISIONING_KEEP_SCREEN_ON",
    provisioningSkipEducationScreens: "android.app.extra.PROVISIONING_SKIP_EDUCATION_SCREENS",
    provisioningWifiSsid: "android.app.extra.PROVISIONING_WIFI_SSID",
    provisioningWifiPassword: "android.app.extra.PROVISIONING_WIFI_PASSWORD",
    provisioningWifiSecurityType: "android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE",
    provisioningWifiHidden: "android.app.extra.PROVISIONING_WIFI_HIDDEN",
    provisioningAdminExtrasBundle: "android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE",
    extraEnrollmentToken: "com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN",
  }
}

const securityType = () => {
  return {
    none: 'NONE', 
    wpa: 'WPA', 
    wep: 'WEP',
    eap: 'EAP',
  }
}

const powerButtonActions = () => {
  return {
    powerButtonActionsUnspecified: 'POWER_BUTTON_ACTIONS_UNSPECIFIED', 
    powerButtonAvailable: 'POWER_BUTTON_AVAILABLE', 
    powerButtonBlocked: 'POWER_BUTTON_BLOCKED',
  }
}

const userControlSettings = () => {
  return {
    userControlSettingsUnspecified: 'USER_CONTROL_SETTINGS_UNSPECIFIED', 
    userControlAllowed: 'USER_CONTROL_ALLOWED', 
    userControlDisallowed: 'USER_CONTROL_DISALLOWED',
  }
}

const systemErrorWarnings = () => {
  return {
    systemErrorWarningsUnspecified: 'SYSTEM_ERROR_WARNINGS_UNSPECIFIED', 
    errorAndWarningsEnabled: 'ERROR_AND_WARNINGS_ENABLED', 
    errorAndWarningsMuted: 'ERROR_AND_WARNINGS_MUTED',
  }
}

const deviceSettings = () => {
  return {
    deviceSettingsUnspecified: 'DEVICE_SETTINGS_UNSPECIFIED', 
    settingsAccessAllowed: 'SETTINGS_ACCESS_ALLOWED', 
    settingsAccessBlocked: 'SETTINGS_ACCESS_BLOCKED',
  }
}

const systemNavigation = () => {
  return {
    systemNavigationUnspecified: 'SYSTEM_NAVIGATION_UNSPECIFIED', 
    navigationEnabled: 'NAVIGATION_ENABLED', 
    navigationDisabled: 'NAVIGATION_DISABLED',
    homeButtonOnly: 'HOME_BUTTON_ONLY',
  }
}

const statusBar = () => {
  return {
    statusBarUnspecified: 'STATUS_BAR_UNSPECIFIED', 
    notificationsAndSystemInfoEnabled: 'NOTIFICATIONS_AND_SYSTEM_INFO_ENABLED', 
    notificationsAndSystemInfoDisabled: 'NOTIFICATIONS_AND_SYSTEM_INFO_DISABLED',
    systemInfoOnly: 'SYSTEM_INFO_ONLY',
  }
}

const playStoreMode = () => {
  return {
    playStoreModeUnspecified: 'PLAY_STORE_MODE_UNSPECIFIED',
    whitelist: 'WHITELIST',
    blacklist: 'BLACKLIST',
  }
}

const notificationType = () => {
  return {
    enrollment: 'ENROLLMENT',
    statusReport: 'STATUS_REPORT',
    command: 'COMMAND',
  }
}

export default {
  abilityList,
  wildcards,
  wipeDataFlag,
  systemUpdateType,
  untrustedAppsPolicy,
  googlePlayProtectVerifyApps,
  developerSettings,
  permissionPolicy,
  commandType,
  passwordPolicies,
  installType,
  permissionGrants,
  autoUpdateMode,
  managedPropertyType,
  orgType,
  processStatus,
  iframeFeature,
  webTokenPermissions,
  gApiUrl,
  deviceState,
  packageName,
  devicePolicyManager,
  securityType,
  powerButtonActions,
  systemErrorWarnings,
  deviceSettings,
  statusBar,
  systemNavigation,
  installTypeLabel,
  playStoreMode,
  notificationType,
  userType,
  airplaneModeState,
  appState,
  userControlSettings
}