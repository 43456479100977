import Vue from 'vue'
import axios from 'axios';
import GH from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  users: [],
  user: {},
  // User add
  btnSaveUserAdd: null,
  btnSaveLoadingUserAdd: null,
  // User edit
  btnSaveUserEdit: null,
  btnSaveLoadingUserEdit: null,
}

const getters = {
  getUsers: state => state.users,
  getUser: state => state.user,
  // User add
  getBtnSaveUserAdd: state => state.btnSaveUserAdd,
  getBtnSaveLoadingUserAdd: state => state.btnSaveLoadingUserAdd,
  // User edit
  getBtnSaveUserEdit: state => state.btnSaveUserEdit,
  getBtnSaveLoadingUserEdit: state => state.btnSaveLoadingUserEdit,
}

const actions = {
  async listUsers({ commit }, options) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/users?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        commit('setUsers', response.data)
        return response.data
      })
  },

  async createUser({}, params) {        
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/users`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: params
    }
    return await axios(config)
  },

  async updateUser({}, params) {        
    var config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/users/${params.userId}`,
      headers: {
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: params.user
    }
    return await axios(config)
  },

  async deleteUser({}, id) {
    var config = {
      method: 'delete',
      url: `${resource_uri}/api/users/${id}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
    await axios(config)
  },

  async viewUser({ commit }, id) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/users/${id}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
    return await axios(config)
      .then((response) => {
        commit('setUser', response.data)
        return response.data
      })
  },

  // User add button trigger
  async btnClickSaveUserAdd({ commit }, params) {
    commit('setBtnSaveUserAdd', params)
  },
  async btnClickSaveLoadingUserAdd({ commit }, params) {
    commit('setBtnSaveLoadingUserAdd', params)
  },

  // User edit button trigger
  async btnClickSaveUserEdit({ commit }, params) {
    commit('setBtnSaveUserEdit', params)
  },
  async btnClickSaveLoadingUserEdit({ commit }, params) {
    commit('setBtnSaveLoadingUserEdit', params)
  },
};

const mutations = {
  setUsers(state, users) {
    state.users = users
  },
  setUser(state, user) {
    state.user = user
  },
  // User add
  setBtnSaveUserAdd(state, params) {
    state.btnSaveUserAdd = params
  },
  setBtnSaveLoadingUserAdd(state, params) {
    state.btnSaveLoadingUserAdd = params
  },
  // User edit
  setBtnSaveUserEdit(state, params) {
    state.btnSaveUserEdit = params
  },
  setBtnSaveLoadingUserEdit(state, params) {
    state.btnSaveLoadingUserEdit = params
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}