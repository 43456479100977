export default [
  {
    path: '/enterprises/:enterpriseId/applications/google',
    name: 'applications-google-manage',
    meta: {
      layout: 'vertical',
      title: 'Google Apps',
      icon: 'mdi-google-play',
      action: 'applications_iframe',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/apps/IframeManage.vue'),
  },
]