<template>
  <div class="d-flex align-center">
    <div
      v-if="!$vuetify.breakpoint.mobile"
    >
      <v-btn
        v-if="$can(abilityList().devicesActionHistory, 'all')"
        text
        class="mx-1"
        :to="{ name: 'devices-action-history' }"
      >
        <v-icon left>
          mdi-clipboard-text-clock
        </v-icon>
        Action History
      </v-btn>
      <v-btn
        v-if="$can(abilityList().devicesMessage, 'all')"
        text
        class="mx-1"
        :to="{ name: 'devices-message' }"
      >
        <v-icon left>
          mdi-message-outline
        </v-icon>
        Messages
      </v-btn>
    </div>
    <v-menu
      v-else
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          class="mx-2"
          v-on="on"
        >
          <v-icon>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-if="$can(abilityList().devicesActionHistory, 'all')"
          class="pl-5 pr-6"
          :to="{ name: 'devices-action-history' }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-clipboard-text-clock</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>Action History</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>Action History</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item
          v-if="$can(abilityList().devicesMessage, 'all')"
          class="pl-5 pr-6"
          :to="{ name: 'devices-message' }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-message-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>Messages</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>Device Messages</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import VH from '@/util/variables'

export default {
  data() {
    return {}
  },
  methods: {
    abilityList() {
      return VH.abilityList()
    },
  },
}
</script>