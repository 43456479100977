export default [
  {
    path: '/',
    name: 'dashboard',
    meta: {
      layout: 'vertical',
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
    },
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
]