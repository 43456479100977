<template>
  <div class="d-flex align-center">
    <div
      v-if="policyName && policyVersion"
      class="mx-4 text-body-1 font-weight-medium"
    >
      <v-tooltip
        bottom
        :max-width="maxWidth($vuetify.breakpoint.mobile)"
      >
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
          >
            <div
              class="text-truncate"
              :style="{ 'max-width': 'calc(' + maxWidth($vuetify.breakpoint.mobile) + 'px)'}"
            >
              {{ policyName }} 
              <v-chip
                outlined
                label
                color="white"
                small
                class="ml-1"
              >
                <v-icon 
                  left 
                  small
                >
                  mdi-tag
                </v-icon> 
                {{ policyVersion }}
              </v-chip>
            </div>
          </div>
        </template>
        <span>
          <span class="text-body-1 font-weight-medium">Editing: </span>{{ policyName }}<br>
          <span class="text-body-1 font-weight-medium">Version: </span>{{ policyVersion }}
        </span>
      </v-tooltip>
    </div>
    <div
      v-if="!$vuetify.breakpoint.mobile"
    >
      <v-btn
        text
        class="mx-1"
        @click="clickBtnJsonView(true)"
      >
        <v-icon left>
          mdi-code-json
        </v-icon>
        JSON View
      </v-btn>
      <v-btn
        v-if="$can(abilityList().enrollmentTokensView, 'all')"
        text
        class="mx-1"
        @click="clickBtnViewQr(true)"
      >
        <v-icon left>
          mdi-qrcode
        </v-icon>
        View QR
      </v-btn>
    </div>
    <v-menu
      v-else
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          class="mx-2"
          v-on="on"
        >
          <v-icon>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          class="pl-5 pr-6"
          @click="clickBtnJsonView(true)"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-code-json</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>JSON View</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>JSON View</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item
          v-if="$can(abilityList().enrollmentTokensView, 'all')"
          class="pl-5 pr-6"
          @click="clickBtnViewQr(true)"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-qrcode</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>View QR</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>View QR</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import VH from '@/util/variables'

export default {
  data() {
    return {
      policyName: null,
      policyVersion: null,
      windowWidth: window.innerWidth,
    }
  },
  watch: {
    '$store.getters.getPolicy': {
      handler () {
        this.policyName = this.$store.getters.getPolicy.name
        this.policyVersion = this.$store.getters.getPolicy.version
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize())
    })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize())
  },
  methods: {
    maxWidth(isMobile) {
      if (isMobile) {
        return this.windowWidth - 260
      } else {
        return this.windowWidth - 1300
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    abilityList() {
      return VH.abilityList()
    },
    clickBtnViewQr(val) {
      this.$store.dispatch('btnClickViewQrPolicyEdit', val)
    },
    clickBtnJsonView(val) {
      this.$store.dispatch('btnClickJsonViewPolicyEdit', val)
    },
  },
}
</script>