import Vuex from 'vuex'
import Vue from 'vue'

import auth from './modules/auth'
import enterprises from './modules/enterprises'
import signupUrls from './modules/signup-urls'
import alert from './modules/alert'
import policies from './modules/policies'
import enrollmentToken from './modules/enrollment-tokens'
import devices from './modules/devices'
import roles from './modules/roles'
import permissions from './modules/permissions'
import apps from './modules/apps'
import customers from './modules/customers'
import organisations from './modules/organisations'
import webTokens from './modules/web-tokens'
import users from './modules/users'
import ui from './modules/ui'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    enterprises,
    signupUrls,
    alert,
    policies: policies,
    enrollmentToken,
    devices,
    roles,
    permissions,
    apps,
    customers,
    organisations,
    webTokens,
    users,
    ui,
  }
})