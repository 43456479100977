export default [
  {
                
    path: '/users',
    name: 'users-list',
    meta: {
      layout: 'vertical',
      title: 'Users',
      icon: 'mdi-account-group-outline',
      action: 'users_list'
    },
    component: () => import('@/views/users/List.vue'),
  },
  {
        
    path: '/users/add',
    name: 'users-create',
    meta: {
      layout: 'vertical',
      title: 'Add User',
      icon: 'mdi-plus',
      action: 'users_create',
      hidden: true,
      breadcrumbAction: 'UserAddAction',
      breadcrumbRedirect: '/users',
      breadcrumbsTitle: 'Users'
    },
    component: () => import('@/views/users/Add.vue'),
  },
  {
        
    path: '/users/:id/view',
    name: 'users-view',
    meta: {
      layout: 'vertical',
      title: 'View User',
      icon: 'mdi-format-list-bulleted',
      hidden: true,
      action: 'users_view',
      breadcrumbAction: 'UserViewAction',
      breadcrumbRedirect: '/users',
      breadcrumbsTitle: 'Users'
    },
    component: () => import('@/views/users/View.vue'),
  },
  {
    path: '/users/:id/edit',
    name: 'users-update',
    meta: {
      layout: 'vertical',
      title: 'Edit User',
      icon: 'mdi-pencil',
      hidden: true,
      action: 'users_update',
      breadcrumbAction: 'UserEditAction',
      breadcrumbRedirect: '/users',
      breadcrumbsTitle: 'Users'
    },
    component: () => import('@/views/users/Edit.vue'),
  },
]