const state = {
  snackbar: {
    visible: false,
    text: null,
    timeout: 6000, //  timeout: -1 (without timeout)
    mode: null,
    color: null,
    x: null,
    y: null,
    icon: null,
  }
}

const getters = {}

const actions = {
  showSnackbar({ commit }, payload) {
    commit('setShowSnackbar', payload)
  },
}

const mutations = {
  setShowSnackbar(state, payload) {
    state.snackbar.text = payload.text

    state.snackbar.mode = (payload.text.length > 50) ? 'multi-line' : null
    if (payload.mode) {
      state.snackbar.mode = payload.mode
    }

    state.snackbar.color = payload.color
    if (payload.color === 'info') {
      state.snackbar.icon = 'mdi-information-outline'
    } else if (payload.color === 'success') {
      state.snackbar.icon = 'mdi-check-circle-outline'
    } else if (payload.color === 'warning') {
      state.snackbar.icon = 'mdi-alert-outline'
    } else if (payload.color === 'error') {
      state.snackbar.icon = 'mdi-alert-octagon-outline'
    } else {
      state.snackbar.icon = ''
    }

    state.snackbar.x = payload.x
    state.snackbar.y = payload.y
    if (payload.timeout) {
      state.snackbar.timeout = payload.timeout
    }
    state.snackbar.visible = true
  },
}

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}