import Vue from 'vue'
import axios from 'axios'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  appGoogle: {},
};

const getters = {
  getAppGoogle: state => state.appGoogle
};

const actions = {
  async viewAppGoogle({ commit }, params) {
    var config = {
      method: 'get',
      url: `${resource_uri}/gapi/enterprises/${params.enterpriseGoogleId}/applications/${params.packageName}`,
      headers: {
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
        
    return await axios(config)
      .then((response) => {
        commit('setAppGoogle', response.data)
        return response.data
      })
  },
};

const mutations = {
  setAppGoogle(state, appGoogle) {
    state.appGoogle = appGoogle
  }
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}