<template>
  <v-container>
    <v-layout
      text-center
      wrap
    >
      <v-flex xs12>
        <v-img
          :src="computeLogo"
          class="my-3"
          contain
          height="200"
        />
      </v-flex>
  
      <v-flex mb-4>
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to Beach MDM
        </h1>
        <p class="subheading font-weight-regular">
          Hello <span class="font-weight-medium">{{ emailId }}</span>, you don't have an account with Beach MDM yet.
          <br>Please email 
          <a href="mailto:support@beachmdm.com">support@beachmdm.com</a>
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>
  
<script>
import Logo from '@/assets/images/logo/logo-blue.png'

export default {
  data() {
    return {
      emailId: null
    }
  },
  computed: {
    computeLogo() {
      return Logo
    },
  },
  mounted() {
    this.getEmailId()
  },
  methods: {
    getEmailId() {
      this.emailId = this.$keycloak.tokenParsed.preferred_username
    }
  },
};
</script>