export default [
  {
    path: '*',
    name: 'not-found',
    meta: {
      hidden: true,
    },
    component: () => import('@/views/error/NotFound.vue'),
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    meta: {
      hidden: true,
    },
    component: () => import('@/views/error/Deny.vue'),
  },
  {
    path: '/error',
    name: 'error',
    meta: {
      hidden: true,
    },
    component: () => import('@/views/error/Error.vue'),
  },
]