export default [
  {
    path: '/enterprises',
    name: 'enterprises-list',
    meta: {
      layout: 'vertical',
      title: 'Enterprises',
      icon: 'mdi-domain',
      action: 'enterprises_list',
    },
    component: () => import('@/views/enterprises/List.vue'),
  },
  {
    path: '/enterprises/add',
    name: 'enterprises-create',
    meta: {
      layout: 'vertical',
      title: 'Add Enterprise',
      icon: 'mdi-plus',
      action: 'enterprises_create',
      hidden: true,
      breadcrumbAction: 'EnterpriseAddAction',
      breadcrumbRedirect: '/enterprises',
      breadcrumbsTitle: 'Enterprises'
    },
    component: () => import('@/views/enterprises/Add.vue'),
  },
  {
    path: '/enterprises/:id/edit',
    name: 'enterprises-update',
    meta: {
      layout: 'vertical',
      title: 'Edit Enterprise',
      icon: 'mdi-pencil',
      hidden: true,
      action: 'enterprises_update',
      breadcrumbAction: 'EnterpriseEditAction',
      breadcrumbRedirect: '/enterprises',
      breadcrumbsTitle: 'Enterprises'
    },
    component: () => import('@/views/enterprises/Edit.vue'),
  },
  {
    path: '/enterprises/token',
    name: 'enterprises-token',
    meta: {
      hidden: true,
      action: 'enterprises_create',
    },
    component: () => import('@/views/enterprises/CallbackUrl.vue'),
  },
]