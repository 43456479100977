import Vue from 'vue'
import axios from 'axios';
import GH from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  permissions: [],
}

const getters = {
  getAllPermissions: state => state.permissions,
}

const actions = {
  async listPermissionsPerOrg({ commit }, options) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/permissions:getPerOrganisation?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    await axios(config)
      .then((response) => {
        commit('setPermissions', response.data)
      })
  },
};

const mutations = {
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}