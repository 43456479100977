import Vue from 'vue'
import axios from 'axios';
import GH from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  customers: [],
  // Customer import
  btnSaveCustomerImport: null,
  btnSaveLoadingCustomerImport: null,
}

const getters = {
  getAllCustomers: state => state.customers,
  // Button trigger
  // Customer import
  getBtnSaveCustomerImport: state => state.btnSaveCustomerImport,
  getBtnSaveLoadingCustomerImport: state => state.btnSaveLoadingCustomerImport,
}

const actions = {
  async listCustomers({ commit }, params) {
    const options = GH.normalizePageFilter({
      page: params.options.page,
      size: params.options.itemsPerPage,
      sortBy: params.options.sortBy,
      sortType: params.options.sortDesc,
      params: params.options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/customers?${options}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    await axios(config)
      .then((response) => {
        commit('setCustomers', response.data)
      })
  },

  async importCustomers({}, params) {

    var data = new FormData()
    data.append('file', params.file)
        
    var config = {
      method: 'post',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/customers:import`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
    return await axios(config)
  },

  // Customer import
  async btnClickSaveCustomerImport({ commit }, params) {
    commit('setBtnSaveCustomerImport', params)
  },
  async btnClickSaveLoadingCustomerImport({ commit }, params) {
    commit('setBtnSaveLoadingCustomerImport', params)
  },
};

const mutations = {
  setCustomers(state, customers) {
    state.customers = customers
  },
  // Customer import
  setBtnSaveCustomerImport(state, params) {
    state.btnSaveCustomerImport = params
  },
  setBtnSaveLoadingCustomerImport(state, params) {
    state.btnSaveLoadingCustomerImport = params
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}