import Vue from 'vue'
import App from './App.vue'
import Welcome from '@/views/welcome/App.vue'
import VueLogger from 'vuejs-logger'
import vuetify from './plugins/vuetify'
import keycloak from './plugins/keycloak'
import router from './router'
import store from './store'
import '@/plugins/acl'
import './assets/scss/style.scss'
// NProgress
import NProgress from 'nprogress'
import '../node_modules/nprogress/nprogress.css'
// Code diff (https://github.com/Shimada666/v-code-diff)
import CodeDiff from 'v-code-diff'
// https://www.npmjs.com/package/vue-cookies
import VueCookies from 'vue-cookies'

// Cookies
Vue.use(VueCookies)
// Code diff
Vue.use(CodeDiff)
// Logger
Vue.use(VueLogger)
// Keycloak
Vue.use(keycloak)

const isLogin = (status) => {
  if (status) {
    new Vue({
      el: '#app',
      vuetify,
      router,
      store,
      render: h => h(App)
    })
  } else {
    new Vue({
      el: '#app',
      vuetify,
      store,
      render: h => h(Welcome)
    })
  }
}

Vue.$keycloak.init({ onLoad: 'login-required' }).then((auth) => {
  if (!auth) {
    window.location.reload()
  } else {
    Vue.$log.info("Authenticated")
    if (Vue.$cookies.isKey('abilities')) {
      isLogin(true)
    } else {
      // Get abilities
      NProgress.start()
      store
        .dispatch('getAbility', Vue.$keycloak)
        .then(() => {
          NProgress.done()
          isLogin(true)
        })
        .catch((error) => {
          NProgress.done()
          Vue.$log.error(error)
          if (error.response && error.response.status === 403) {
            isLogin(false)
          }
        })
    }
  }

  //Token Refresh
  setInterval(() => {
    Vue.$keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        Vue.$log.info('Token refreshed ' + refreshed);
      } else {
        Vue.$log.warn('Token not refreshed, valid for '
          + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      Vue.$log.error('Failed to refresh token');
    });
  }, 6000)

}).catch(() => {
  Vue.$log.error("Authenticated Failed");
});