export default [
  {
        
    path: '/roles',
    name: 'roles-list',
    meta: {
      layout: 'vertical',
      title: 'Roles',
      icon: 'mdi-server',
      action: 'roles_list'
    },
    component: () => import('@/views/roles/List.vue'),
  },
  {
        
    path: '/roles/add',
    name: 'roles-create',
    meta: {
      layout: 'vertical',
      title: 'Add Role',
      icon: 'mdi-plus',
      action: 'roles_create',
      hidden: true,
      breadcrumbAction: 'RoleAddAction',
      breadcrumbRedirect: '/roles',
      breadcrumbsTitle: 'Roles'
    },
    component: () => import('@/views/roles/Add.vue'),
  },
  {
    path: '/roles/:id/organisations/:orgId/edit',
    name: 'roles-update',
    meta: {
      layout: 'vertical',
      title: 'Edit Role',
      icon: 'mdi-pencil',
      hidden: true,
      action: 'roles_update',
      breadcrumbAction: 'RoleEditAction',
      breadcrumbRedirect: '/roles',
      breadcrumbsTitle: 'Roles'
    },
    component: () => import('@/views/roles/Edit.vue'),
  },
  {
        
    path: '/roles/:id/organisations/:orgId/view',
    name: 'roles-view',
    meta: {
      layout: 'vertical',
      title: 'View Role',
      icon: 'mdi-format-list-bulleted',
      hidden: true,
      action: 'roles_view',
      breadcrumbAction: 'RoleViewAction',
      breadcrumbRedirect: '/roles',
      breadcrumbsTitle: 'Roles'
    },
    component: () => import('@/views/roles/View.vue'),
  },
]