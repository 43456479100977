import Vue from 'vue'
import axios from 'axios';

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL
const projectId = process.env.VUE_APP_PROJECT_ID

const state = {
  signupUrls: []
};

const getters = {
  allSignupUrls: state => state.signupUrls
};

const actions = {
  async createSignupUrl({ commit }, callbackUrl) {
    var data = new FormData()
    data.append('projectId', projectId)
    data.append('callbackUrl', callbackUrl)

    var config = {
      method: 'post',
      url: `${resource_uri}/api/signup-urls`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
    await axios(config)
      .then((response) => {
        commit('newSignupUrl', response.data)
      })
  }
};

const mutations = {
  newSignupUrl(state, signupUrl) {
    state.signupUrls = signupUrl
  }
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}