<template>
  <figure class="c-avatar">
    <slot name="top" />
    <img
      v-if="src"
      class="c-avatar-image"
      :style="imgStyles"
      :src="src"
      alt=""
    >
    <div
      v-else
      class="c-avatar-inital"
      :style="charStyles"
    >
      {{ username.charAt(0).toUpperCase() }}
    </div>
    <v-icon
      v-if="icon"
      size="18"
      class="c-avatar-icon"
    >
      {{ icon }}
    </v-icon>
    <span
      v-else-if="status"
      :class="statusClasses"
      :style="indicatorStyles"
    />
  </figure>
</template>
  
<script>
import { convertToUnit } from 'vuetify/lib/util/helpers'
export default {
  props: {
    src: {
      type: [String, null],
      default: undefined,
    },
    size: {
      type: [Number, String],
      default: 48,
    },
    status: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      default: undefined,
    },
    username: {
      type: String,
      default: '',
    },
    color: {
      type: [String, Number],
      default: '#2196f3',
    },
  },
  data() {
    return {}
  },
  computed: {
    imgStyles() {
      return {
        height: convertToUnit(this.size),
        minWidth: convertToUnit(this.size),
        width: convertToUnit(this.size),
      }
    },
    charStyles() {
      return {
        height: convertToUnit(this.size),
        minWidth: convertToUnit(this.size),
        width: convertToUnit(this.size),
        'background-color': this.color,
      }
    },
    indicatorStyles() {
      const size = this.size / 3
      return {
        height: convertToUnit(size),
        minWidth: convertToUnit(size),
        width: convertToUnit(size),
      }
    },
    statusClasses() {
      return ['c-avatar-status', this.status]
    },
  },
}
</script>
<style lang="scss" scoped>
    .c-avatar {
        position: relative;
        display: inline-flex;
        &-image {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 100% !important;
        }
        &-inital {
            width: 100px;
            height: 100px;
            object-fit: cover;
            display: inline-flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            border-radius: 100%;
        }
        &-icon {
            position: absolute !important;
            bottom: 2%;
            right: 2%;
            border-radius: 100%;
        }
        &-status {
            border: 1px solid;
            position: absolute;
            bottom: 0;
            right: 0;
            border-radius: 100%;
        }
        &-status.online {
            background: #4caf50;
        }
        &-alert {
            position: absolute;
            top: 2%;
            left: 2%;
            border-radius: 100%;
            height: 17px !important;
            min-width: 17px !important;
        }
        &-alert.alert {
            background: #f44336 !important;
            color: #fff;
            font-size: 12px;
        }
        &-status.idle {
            background: #f5a532;
        }
        &-status.busy {
            background: #f44336;
        }
        &-status.offline {
            background: #757f8d;
        }
    }
</style>  