<template>
  <v-hover v-slot="{ hover }">
    <v-navigation-drawer
      v-model="showDrawer"
      app
      :expand-on-hover="isMiniDrawer"
      :mini-variant="isMiniDrawer"
      class="app-drawer"
    >
      <v-toolbar
        flat
      >
        <img
          :src="computeLogo"
          height="40"
          alt="Beach MDM"
        >
        <v-toolbar-title class="pl-3">
          <span 
            class="text-body-1" 
            :style="{ 'font-size': '20px !important' }"
          >
            Beach MDM
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="isMiniDrawer = !isMiniDrawer"
        >
          <v-icon>
            {{ isMiniDrawer ? 'mdi-checkbox-blank-circle-outline' : 'mdi-record-circle-outline' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <div
        v-if="!isMiniDrawer || hoverEvent(hover)"
        ref="enterpriseSwitcher"
        :style="{ 'padding-top': '1px' }"
      >
        <EnterpriseSwitcher
          class="pt-1 pb-0"
          @enableMiniDrawer="isMiniDrawer = $event"
        />
      </div>
      <VuePerfectScrollbar class="app-drawer-scrollbar">
        <div :style="appDrawerInner">
          <NavList :items="computeMenu" />
        </div>
      </VuePerfectScrollbar>
    </v-navigation-drawer>
  </v-hover>
</template>

<script>
import routes from '@/router'
import NavList from '@/components/nav/NavList'
import EnterpriseSwitcher from '@/components/layouts/components/EnterpriseSwitcher'
import Logo from '@/assets/images/logo/logo-blue.png'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { replaceEnterpriseIdParam } from '@/util/helpers'

export default {
  components: {
    NavList,
    EnterpriseSwitcher,
    VuePerfectScrollbar,
  },
  props: {},
  data() {
    return {
      showDrawer: true,
      scrollSettings: {
        maxScrollbarLength: 160,
      },
      appDrawerInner: null,
      isMiniDrawer: this.$vuetify.breakpoint.mobile ? false : true,
      isExpand: false,
      isExec: false
    }
  },
  computed: {
    computeLogo() {
      return Logo
    },
    computeMenu() {
      const enterpriseId = this.$store.getters.getActivateEnterprise ? this.$store.getters.getActivateEnterprise.id : null
      const menu = this.filterRouteItem(routes.options.routes, enterpriseId)
        .filter((item) => !(item.action === 'initial' && item.children.length === 0))
      return menu
    },
  },
  mounted() {
    this.appDrawerInnerFunc()
  },
  methods: {
    appDrawerInnerFunc(defaultHeight = 0) {
      const switcherHeight = this.$refs.enterpriseSwitcher ? this.$refs.enterpriseSwitcher.clientHeight : defaultHeight
      this.appDrawerInner = {
        'height': `calc(100vh - ${document.getElementsByTagName("header")[0].style.height} - ${switcherHeight}px)`
      }
    },
    hoverEvent(isHover) {
      if (isHover && !this.isExec) {
        this.appDrawerInnerFunc(43)
        this.isExec = true
      } else if (!isHover) {
        this.isExec = false
      }
      return isHover
    },
    filterRouteItem(routes, enterpriseId) {
      return routes
        .filter((item) => item.meta.hidden !== true)
        .reduce((result, item) => {
          if (item.meta.action === undefined || this.$ability.can(item.meta.action, item.meta.resource)) {
            if (item.meta.isShowParent === false) {
              this.filterRouteItem(item.children, enterpriseId).map((childItem) => {result.push(childItem)})
            } else {
              result.push({
                title: item.meta.title,
                icon: item.meta.icon,
                path: replaceEnterpriseIdParam(item.path, enterpriseId),
                action: item.meta.action,
                isNew: item.meta.isNew || false,
                children: item.children ? this.filterRouteItem(item.children, enterpriseId) : [],
              })
            }
          }
          return result
        }, [])
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer
    },
  },
}
</script>
<style lang="scss" scoped>
.app-drawer {
  &-scrollbar {
    max-height: 100vh;
  }
}
</style>