<template>
  <div>
    <AppDrawer ref="drawer" />
    <!-- Blur component -->
    <v-card 
      tile
      class="top-blur elevation-0 grey lighten-4"
    />
    <!-- Toolbar -->
    <AppToolbar
      class="admin-toolbar"
      extended
      @side-icon-click="handleDrawerVisiable"
    />
    <v-main class="grey lighten-4">
      <!-- Page Wrapper -->
      <div 
        class="mt-10 ma-5"
        :style="pageWrapper"
      >
        <router-view />
      </div>
    </v-main>
    <!-- Go to top -->
    <AppFab />
  </div>
</template>
<script>
import AppDrawer from '@/components/layouts/components/AppDrawer'
import AppToolbar from '@/components/layouts/components/toolbar/AppToolbar'
import AppFab from '@/components/layouts/components/AppFab'

export default {
  components: {
    AppDrawer,
    AppToolbar,
    AppFab,
  },
  data() {
    return {}
  },
  computed: {
    pageWrapper() {
      return {
        'min-height': `calc(100vh - ${this.$store.getters.getFrameSize.header.height}px - 60px)`
      }
    }
  },
  watch: {
    '$route': {
      handler () {
        this.resizeListener()
      },
    },
  },
  mounted() {
    this.resizeListener()
  },
  methods: {
    resizeListener() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize())
      })
    },
    onResize() {
      const headerHeight = document.querySelector(".v-app-bar.v-app-bar--fixed").offsetHeight
      this.$store.dispatch('frameSize', {
        header: {
          height: headerHeight
        }
      })
    },
    handleDrawerVisiable() {
      this.$refs.drawer.toggleDrawer()
    },
  }
}
</script>
<style>
.top-blur { 
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 50px;
  filter: blur(1.5rem);
  -webkit-filter: blur(1.5rem);
}
</style>