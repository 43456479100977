<template>
  <div class="d-flex align-center">
    <div
      v-if="!$vuetify.breakpoint.mobile"
    >
      <v-btn
        v-if="$can(abilityList().policiesCreate, 'all')"
        text
        class="mx-1"
        :to="{ name: 'policies-import' }"
      >
        <v-icon left>
          mdi-database-import
        </v-icon>
        Import
      </v-btn>
      <v-btn
        v-if="$can(abilityList().policiesLog, 'all')"
        text
        class="mx-1"
        :to="{ name: 'policies-history' }"
      >
        <v-icon left>
          mdi-clipboard-text-clock
        </v-icon>
        History
      </v-btn>
      <v-btn
        v-if="$can(abilityList().policiesCompare, 'all')"
        text
        class="mx-1"
        :to="{ name: 'policies-compare' }"
      >
        <v-icon left>
          mdi-file-compare
        </v-icon>
        Compare
      </v-btn>
      <v-btn
        v-if="$can(abilityList().enrollmentTokensView, 'all')"
        text
        class="mx-1"
        :to="{ name: 'policies-qr-search' }"
      >
        <v-icon left>
          mdi-qrcode-scan
        </v-icon>
        QR Search
      </v-btn>
    </div>
    <v-menu
      v-else
      offset-y
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          icon
          class="mx-2"
          v-on="on"
        >
          <v-icon>
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item
          v-if="$can(abilityList().policiesCreate, 'all')"
          class="pl-5 pr-6"
          :to="{ name: 'policies-import' }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-database-import</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>Import</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>Import Policy</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item
          v-if="$can(abilityList().policiesLog, 'all')"
          class="pl-5 pr-6"
          :to="{ name: 'policies-history' }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-clipboard-text-clock</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>History</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>History Policy</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item
          v-if="$can(abilityList().policiesCompare, 'all')"
          class="pl-5 pr-6"
          :to="{ name: 'policies-compare' }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-file-compare</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>Compare</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>Compare Policy</span>
          </v-tooltip>
        </v-list-item>
        <v-list-item
          v-if="$can(abilityList().enrollmentTokensView, 'all')"
          class="pl-5 pr-6"
          :to="{ name: 'policies-qr-search' }"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-qrcode</v-icon>
              </v-list-item-icon>
              <v-list-item-content
                v-bind="attrs"
                v-on="on"
              >
                <v-list-item-title>QR Search</v-list-item-title>
              </v-list-item-content>
            </template>
            <span>QR Search</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import VH from '@/util/variables'

export default {
  data() {
    return {}
  },
  methods: {
    abilityList() {
      return VH.abilityList()
    },
  },
}
</script>