export default [
  {
    path: '/enterprises/:enterpriseId/customers',
    name: 'customers-list',
    meta: {
      layout: 'vertical',
      title: 'Customers',
      icon: 'mdi-account-card-outline',
      action: 'customers_list',
      toolbarAction: 'CustomerListToolbar',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/customers/List.vue'),
  },
  {
    path: '/enterprises/:enterpriseId/customers/import',
    name: 'customers-import',
    meta: {
      layout: 'vertical',
      title: 'Import',
      icon: 'mdi-database-import',
      action: 'customers_create',
      hidden: true,
      breadcrumbRedirect: '/enterprises/:enterpriseId/customers',
      breadcrumbsTitle: 'Customers',
      breadcrumbAction: 'CustomerImportAction',
      isEnterpriseRequired: true
    },
    component: () => import('@/views/customers/Import.vue'),
  },
]