import Vue from 'vue'
import axios from 'axios';
import GH from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  childOrgs: [],
  org: {},
  // Org add
  btnSaveOrgAdd: null,
  btnSaveLoadingOrgAdd: null,
  // Org edit
  btnSaveOrgEdit: null,
  btnSaveLoadingOrgEdit: null,
}

const getters = {
  getOrgChild: state => state.childOrgs,
  getOrg: state => state.org,
  // Org add
  getBtnSaveOrgAdd: state => state.btnSaveOrgAdd,
  getBtnSaveLoadingOrgAdd: state => state.btnSaveLoadingOrgAdd,
  // Org edit
  getBtnSaveOrgEdit: state => state.btnSaveOrgEdit,
  getBtnSaveLoadingOrgEdit: state => state.btnSaveLoadingOrgEdit,
}

const actions = {
  async listChildOrgs({ commit }, options) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/organisations?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    await axios(config)
      .then((response) => {
        commit('setChildOrgs', response.data)
      })
  },

  async createOrg({}, params) {
    var data = JSON.stringify({
      "name": params.name,
      "parentId": params.parentId,
      "supportNumber": params.supportNumber
    })
        
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/organisations`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: data
    }
    return await axios(config)
  },

  async viewOrg({ commit }, orgId) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/organisations/${orgId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
    return await axios(config)
      .then((response) => {
        commit('setOrg', response.data)
        return response.data
      })
  },

  async updateOrg({}, params) {
    var data = JSON.stringify({
      "name": params.name,
      "parentId": params.parentId,
      "supportNumber": params.supportNumber
    })
        
    var config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/organisations/${params.orgId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: data
    }
    return await axios(config)
  },

  async deleteOrg({}, id) {
    var config = {
      method: 'delete',
      url: `${resource_uri}/api/organisations/${id}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
    await axios(config)
  },

  // Org add button trigger
  async btnClickSaveOrgAdd({ commit }, params) {
    commit('setBtnSaveOrgAdd', params)
  },
  async btnClickSaveLoadingOrgAdd({ commit }, params) {
    commit('setBtnSaveLoadingOrgAdd', params)
  },

  // Org edit button trigger
  async btnClickSaveOrgEdit({ commit }, params) {
    commit('setBtnSaveOrgEdit', params)
  },
  async btnClickSaveLoadingOrgEdit({ commit }, params) {
    commit('setBtnSaveLoadingOrgEdit', params)
  },
};

const mutations = {
  setChildOrgs(state, childOrgs) {
    state.childOrgs = childOrgs
  },
  setOrg(state, org) {
    state.org = org
  },
  // Org add
  setBtnSaveOrgAdd(state, params) {
    state.btnSaveOrgAdd = params
  },
  setBtnSaveLoadingOrgAdd(state, params) {
    state.btnSaveLoadingOrgAdd = params
  },
  // Org edit
  setBtnSaveOrgEdit(state, params) {
    state.btnSaveOrgEdit = params
  },
  setBtnSaveLoadingOrgEdit(state, params) {
    state.btnSaveLoadingOrgEdit = params
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}