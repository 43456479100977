const state = {
  // Frame size
  frameSize: {
    header: {
      height: 0
    }
  },
  refreshBreadcrumbs: null
}

const getters = {
  // Frame size
  getFrameSize: state => state.frameSize,
  getRefreshBreadcrumbs: state => state.refreshBreadcrumbs,
}

const actions = {
  // Frame size
  async frameSize({ commit }, params) {
    commit('setFrameSize', params)
  },

  async refreshBreadcrumbs({ commit }, newBreadcrumbs) {
    commit('setRefreshBreadcrumbs', newBreadcrumbs)
  },
};

const mutations = {
  // Frame size
  setFrameSize(state, params) {
    state.frameSize = params
  },
  // Breadcrumbs
  setRefreshBreadcrumbs(state, params) {
    state.refreshBreadcrumbs = params
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}