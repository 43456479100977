import Vue from 'vue'
import axios from 'axios'
import GH from '@/util/helpers'

const resource_uri = process.env.VUE_APP_BACKEND_BASE_URL;

const state = {
  devices: [],
  device: [],
  devicesActionHistory: [],
};

const getters = {
  getAllDevices: state => state.devices,
  getDevice: state => state.device,
};

const actions = {
  async listDevices({ commit }, { enterpriseId, options }) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        commit('setDevices', response.data)
        return response.data
      })
  },

  async listDevicesSearch({ commit }, { enterpriseId, options }) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices:singleSearch?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        commit('setDevices', response.data)
        return response.data
      })
  },

  async exportListDevicesSearch({ commit }, { enterpriseId, options }) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices:export?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      responseType: 'blob',
      timeout: 0
    }
        
    return await axios(config)
      .then((response) => {
        return response
      })
  },

  async listDevicesActionHistory({ commit }, { enterpriseId, options }) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices:actionHistories?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        commit('setDevicesActionHistory', response.data)
        return response.data
      })
  },

  async listDeviceMessages({}, { enterpriseId, options }) {
    const params = GH.normalizePageFilter({
      page: options.page,
      size: options.itemsPerPage,
      sortBy: options.sortBy,
      sortType: options.sortDesc,
      params: options.params
    })
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices:messageList?${params}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async viewDeviceMessage({}, params) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices/${params.deviceId}/messages/${params.messageId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async syncDevices({}, enterpriseId) {
    const config = {
      method: 'put',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices:sync`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    await axios(config)
  },

  async syncDevicesZeroTouch({}, enterpriseId) {
    const config = {
      method: 'put',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices:syncZeroTouch`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    await axios(config)
  },

  async syncDevice({}, { enterpriseId, deviceId }) {
    const config = {
      method: 'put',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices/${deviceId}:sync`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async issueCommand({}, params) {     
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices/${params.deviceId}:issueCommand`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: params.command
    }
    await axios(config)
  },

  async issueCommandBulk({}, params) {        
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices:bulkIssueCommand`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(params.data)
    }
    await axios(config)
  },

  async lockUnlockBulk({}, params) {        
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices:lockUnlockDevices`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(params.body)
    }
    await axios(config)
  },

  async deleteDevice({}, params) {
    const data = new FormData()
    params.wipeDataFlags.forEach(function (value, index) {
      data.append('wipeDataFlags', value)
    })
    data.append('wipeReasonMessage', params.wipeReasonMessage)
        
    const config = {
      method: 'delete',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices/${params.deviceId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
        
    await axios(config)
  },

  async deleteBulkDevice({}, params) {      
    const config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices:bulkDelete`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(params.data)
    }
    await axios(config)
  },

  async factoryResetDevice({}, params) {
    const data = new FormData()
    params.wipeDataFlags.forEach(function (value, index) {
      data.append('wipeDataFlags', value)
    })
    data.append('wipeReasonMessage', params.wipeReasonMessage)
        
    const config = {
      method: 'patch',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices/${params.deviceId}:factoryReset`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
      data: data
    }
        
    await axios(config)
  },

  async factoryResetBulkDevice({}, params) {      
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices:bulkFactoryReset`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(params.data)
    }
    await axios(config)
  },

  async changePolicyBulkDevice({}, params) {      
    const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices:bulkChangePolicy`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(params.data)
    }
    await axios(config)
  },

  async viewDevice({ commit }, params) {
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices/${params.deviceId}`,
      headers: {
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      },
    }
    return await axios(config)
      .then((response) => {
        commit('setDevice', response.data)
        return response.data
      })
  },

  async updateDevice({}, params) {
    var data = JSON.stringify(params.body)
        
    var config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${resource_uri}/api/enterprises/${params.enterpriseId}/devices/${params.deviceId}`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`,
        'Content-Type': 'application/json',
      },
      data: data
    }
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async countDevicesVersion({}, enterpriseId) {
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices:getVersions`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async countDevicesEnrollment({}, enterpriseId) {
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices:countEnrollmentStatus`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },

  async countDevicesModel({}, enterpriseId) {
        
    var config = {
      method: 'get',
      url: `${resource_uri}/api/enterprises/${enterpriseId}/devices:countModels`,
      headers: { 
        'Authorization': `Bearer ${Vue.$keycloak.token}`
      }
    }
        
    return await axios(config)
      .then((response) => {
        return response.data
      })
  },
};

const mutations = {
  setDevices(state, devices) {
    state.devices = devices
  },
  setDevice(state, device) {
    state.device = device
  },
  setDevicesActionHistory(state, devicesActionHistory) {
    state.devicesActionHistory = devicesActionHistory
  },
};

export default {
  state: state, 
  getters: getters, 
  actions: actions, 
  mutations: mutations
}