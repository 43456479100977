import VH from './variables'
import moment from 'moment-timezone'

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const toggleFullScreen = () => {
  let doc = window.document
  let docEl = doc.documentElement

  let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen
  let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen

  if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
    requestFullScreen.call(docEl)
  } else {
    cancelFullScreen.call(doc)
  }
}

const normalizePageFilter = (options) => {
  const strFilter = new URLSearchParams()
  strFilter.append('page', options.page)
  strFilter.append('size', options.size)
    
  // Normalize sortBy and sortType
  if (options.sortBy && options.sortBy.length > 0) {
    for (const [key, value] of Object.entries(options.sortBy)) {
      strFilter.append('sortBy', value)
    }
    
    for (const [key, value] of Object.entries(options.sortType)) {
      strFilter.append('sortType', value === false ? 'ASC' : 'DESC')
    }
  }
    
  // Normalize params
  for (const [key, value] of Object.entries(options.params)) {
    if (value === null) {
      strFilter.append(key, '')
    } else {
      strFilter.append(key, value)
    }
  }
    
  return strFilter
}

const normalizeParams = (params) => {
  const strParam = new URLSearchParams()
  for (const [key, value] of Object.entries(params)) {
    strParam.append(key, value)
  }
    
  return strParam
}

const isJson = (strJson) => {
  try {
    if (strJson === undefined || strJson === '' || strJson === null) return true
    JSON.parse(strJson)
  } catch (e) {
    return false
  }
  return true
}

// Check if enterprise selected or not
const checkEnterpriseSelected = () => {
  const enterprise = localStorage.getItem('enterprise')
  if (enterprise && isJson(enterprise) && enterprise !== 'null') {
    if (JSON.parse(enterprise).id) {
      return true
    }
    return false
  }
  return false
}

export const prettyJson = (val) => {
  return JSON.stringify(val, null, 4)
}

const jsonToTreeProcess = (treeData, json) => {
  for (let [key, value] of Object.entries(json)) {
    treeData.children = []
    treeData.push({
      name: key,
      children: typeof(value) === 'object' ? jsonToTreeProcess(treeData.children, value) : [{name: `${value}`}],
    })
  }
  return treeData
}

export const jsonToTreeview = (json) => {
  const treeData = []
  return jsonToTreeProcess(treeData, json)
}

const normalizeMenu = (menuList, can) => {
  for(let i = 0; i < menuList.length; i++) {
    if(menuList[i].menu) {
      for(let j = 0; j < menuList[i].menu.length; j++) {
        if(!can(menuList[i].menu[j].action, 'all')) {
          menuList[i].menu.splice(j, 1)
        }
      }
    }
  }
  return menuList
}

export const getLastWordSplitBySlash = (word) => {
  return word.split('/').pop()
}

const getLastWordSplitByDot = (word) => {
  return word.split('.').pop()
}

const replaceUnderscoreBySpace = (word) => {
  return word.replaceAll('_', ' ');
}

export const openSnackbar = (store, payload) => {
  store.dispatch('showSnackbar', {
    text: payload.text,
    mode: payload.mode,
    color: payload.color,
    x: payload.x,
    y: payload.y,
    timeout: payload.timeout,
  })
}

const alertEnterpriseSelected = (store, router) => {
  openSnackbar(store, {
    text: "Please select the enterprise first",
    color: 'warning',
    x: 'right',
    y: 'top'
  })
  if (router) router.push({name: 'dashboard'})
}

export const alertSnackbar = (store, error) => {
  if (error.response && error.response.data && error.response.data.message) {
    var message = error.response.data.message
    if (Array.isArray(error.response.data.message)) {
      message = error.response.data.message.join('\n')
    }
    openSnackbar(store, {
      text: message,
      color: 'warning',
      x: 'right',
      y: 'top'
    })
  } else if (error.message) {
    openSnackbar(store, {
      text: error.message,
      color: 'error',
      x: 'right',
      y: 'top'
    })
  } else {
    openSnackbar(store, {
      text:'Something went wrong',
      color: 'error',
      x: 'right',
      y: 'top'
    })
  }
}

export const manageAppUrl = (params) => {
  return `https://play.google.com/work/embedded/search?${normalizeParams(params)}`
}

export const zeroTouchUrl = (params) => {
  return `https://enterprise.google.com/android/zero-touch/embedded/companyhome?${normalizeParams(params)}`
}

export const rowsPerPage = () => {
  return [25, 50, 100]
}

export const convertDate = (datetime) => {
  return moment.utc(datetime).tz(timezone).format('MMMM Do YYYY, h:mm:ss a')
}

export const convertDateDb = (datetime) => {
  return datetime ? moment.utc(datetime).tz(timezone).format() : datetime
}

export const getFilenameFromResponse = (response, fileExt) => {
  const disposition = response.headers['content-disposition']
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(disposition)
  return matches != null && matches[1] ? matches[1].replace(/['"]/g, '') : 'download.' + fileExt
}

export const processStatusLabel = () => {
  return {
    PENDING: 'PENDING',
    ON_PROCESS: 'ON PROCESS',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
  }
}

export const processStatusCombo = () => {
  return [
    {
      id: VH.processStatus().pending, 
      name: processStatusLabel().PENDING
    },
    {
      id: VH.processStatus().onProcess, 
      name: processStatusLabel().ON_PROCESS
    },
    {
      id: VH.processStatus().success,
      name: processStatusLabel().SUCCESS
    },
    {
      id: VH.processStatus().failed,
      name: processStatusLabel().FAILED
    }
  ]
}

export const timestampToDateTime = (timestamp) => {
  return convertDateDb(new Date(timestamp * 1000))
}

const getClientTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const replaceEnterpriseIdParam = (path, enterpriseId) => {
  return path.includes(":enterpriseId") ? path.replace(":enterpriseId", enterpriseId) : path
}

export const containParams = (path) => {
  if (path) return path.includes(":")
  else false
}

export const tableResponsive = (appHeaderHeight, elTabelFooterHeight, elTableHeaderHeight) => {
  let tableHeaderHeight = 64
  let tableFooterHeight = 59
  if (elTabelFooterHeight) {
    tableFooterHeight = elTabelFooterHeight.offsetHeight
  }
  if (elTableHeaderHeight) {
    tableHeaderHeight = elTableHeaderHeight.offsetHeight
  }
  return `calc(100vh - ${appHeaderHeight}px - 40px - ${tableHeaderHeight}px - ${tableFooterHeight}px)` // 100vh - [v-app-bar + v-breadcrumbs] - [padding] - [v-table-header] - [v-table-footer] in mobile view
}

export const pageWrapper = (appHeaderHeight) => {
  return {
    'height': `calc(100vh - ${appHeaderHeight}px - 60px)`
  }
}

export const frameWrapper = (appHeaderHeight) => {
  return {
    'min-height': `calc(100vh - ${appHeaderHeight}px - 60px)`
  }
}

export const customSearchToJson = (strSearch, jsonSearch, defaultKey) => {
  if (strSearch) {
    var arr = strSearch.split(' ')
    for (var i = 0; i < arr.length; i++) {
      var subArr = arr[i].split(':')
      if (subArr.length == 2) { // Check if array has key
        if (jsonSearch.hasOwnProperty(subArr[0])) { // Ignored undefined key
          jsonSearch[subArr[0]] = subArr[1]
        }
      } else {
        jsonSearch[defaultKey] = subArr[0]
      }
    }
  }
  return jsonSearch
}

export const customSearchToText = (jsonSearch, defaultKey) => {
  const values = []
  // Iterate through properties of the data object
  for (const key in jsonSearch) {
    if (jsonSearch[key] !== null && jsonSearch[key] !== undefined) {
      key === defaultKey ? values.push(`${jsonSearch[key]}`) : values.push(`${key}:${jsonSearch[key]}`)
    }
  }

  // Join the array into a string
  return values.join(' ')
}

export const paginationMeta = (options, total) => {
  const start = (options.page - 1) * options.itemsPerPage + 1
  const end = Math.min(options.page * options.itemsPerPage, total)
    
  return `${start} - ${end} of ${total}`
}

export default {
  openSnackbar,
  getLastWordSplitByDot,
  replaceUnderscoreBySpace,
  getLastWordSplitBySlash,
  toggleFullScreen,
  normalizePageFilter,
  checkEnterpriseSelected,
  prettyJson,
  isJson,
  jsonToTreeview,
  normalizeMenu,
  normalizeParams,
  manageAppUrl,
  zeroTouchUrl,
  rowsPerPage,
  convertDate,
  alertSnackbar,
  processStatusLabel,
  processStatusCombo,
  timestampToDateTime,
  alertEnterpriseSelected,
  getClientTimeZone,
  convertDateDb,
  tableResponsive,
}